import WrapPage from '../components/WrapPage';
export default WrapPage;

export async function getServerSideProps(context) {
  if (
    context &&
    context.query &&
    Object.keys(context.query).includes('_escaped_fragment_') &&
    context.query._escaped_fragment_.length > 1
  ) {
    context.res.statusCode = 302;
    context.res.setHeader('Location', `/`);
    return {
      notFound: true,
    };
  }
  try {
    const res = await fetch(
      `${process.env.API_SERVER_URL_LOCAL}/app/post/page-detail/home?limit=6}`,
      {
        headers: {
          'Content-Type': 'application/json',
          language: context.locale,
        },
      },
    );
    const data = await res.json();
    if (!data || data.code !== 200) {
      return {
        props: {}
      };
    }

    return {
      props: data.data
    };
  } catch (error) {
    console.log(error);
    return {
      props: {},
    };
  }
}
